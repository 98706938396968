<template>
  <v-layout
    column
    justify-start
    :class="!bMini ? 'viewLayoutWrapper MiniPadding' : 'viewLayoutWrapper'"
  >
    <!-- Start title -->
    <div class="SectionTitle d-flex align-center">
      <v-btn icon fab small @click="goBack()">
        <v-icon style="font-size: 30px; color: black">
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <span> {{ bAddOrEdit ? "Agregar" : "Editar" }} usuario </span>
    </div>
    <AddOrEdit @goBack="goBack" />
  </v-layout>
</template>

<script>
import AddOrEdit from "@/components/views/Users/AddOrEdit.vue";

export default {
  name: "AddOrEditUser",
  props: {
    texts: Object,
    mdUp: Boolean,
    smUp: Boolean,
    bMini: Boolean,
  },
  data() {
    return {
      
    };
  },
  beforeMount() {
  },
  methods: {
    // get detail user

    goBack() {
      this.$router.push({
        name: "Users",
      });
    },
  },
  computed: {
    bAddOrEdit() {
      return this.$route.params.sIdUser === "0";
    },
  },
  watch: {},
  components: {
    AddOrEdit,
  },
};
</script>

<style>
.v-progress-linear__content {
  align-items: center;
  display: flex;
  height: 100%;
  left: 0;
  justify-content: center !important;
  margin-left: 5px !important;
  position: absolute;
  top: 0;
  width: 100%;
}
.btn-sincategorizar[disabled] {
  color: transparent;
}
.footer-table {
  background-color: #fff;
  height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.footer-table p {
  margin-bottom: 0;
  color: #696969;
  font-size: 12px;
  letter-spacing: 0;
}

.footer-table-buttons {
  background-color: #fff !important;
}

.footer-table-buttons i {
  font-size: 24px;
  opacity: 0.54;
  color: #475f7b !important;
}

.footer-table-dropdown {
  opacity: 0.54;
  color: #475f7b;
  font-size: 12px;
  letter-spacing: 0;
}

.v-tabs-slider-wrapper {
  height: 6px !important;
  border-radius: 10px !important;
  border: solid 1px white;
  border-bottom-color: #ffce00;
  width: auto;
}

.v-slide-group__wrapper {
  border: solid 1px white;
  border-bottom-color: #c4c4c4;
  width: auto;
}
</style>
<style scoped>
.text-progressbar {
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
  color: #2e2e2e;
  font-weight: 600;
  font-size: 12px;
}

.texto-barra-porcentual {
  font-family: "Poppins", sans-serif;
  color: #2e2e2e;
  font-weight: 600;
}

.global-tab {
  font-family: "Poppins", sans-serif !important;
  color: black;
  /* letter-spacing: inherit !important; */
  text-transform: none !important;
  width: 250px !important;
  font-size: 15px !important;
  letter-spacing: -0px !important;
  font-weight: bolder;
}
</style>
