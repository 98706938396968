<template>
  <div class="content-add-or-edit-user-global">
    <v-row>
      <v-col
        cols="12"
        sm="6"
        :md="bAddOrEdit ? '4' : '6'"
        :lg="bAddOrEdit ? '4' : '6'"
        :xl="bAddOrEdit ? '4' : '6'"
      >
        <div class="content-txt-result-global">
          <v-text-field
            v-model="nombreUsuario"
            class="text-Poppins"
            label="Nombre*"
            :hide-details="true"
          >
            <template v-slot:label>
              <span>Nombre <span class="color-red">*</span></span>
            </template>
          </v-text-field>
        </div>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        :md="bAddOrEdit ? '4' : '6'"
        :lg="bAddOrEdit ? '4' : '6'"
        :xl="bAddOrEdit ? '4' : '6'"
      >
        <div class="content-txt-result-global">
          <v-text-field
            v-model="correoUsuario"
            class="text-Poppins"
            label="Correo electrónico*"
            :hide-details="true"
          >
            <template v-slot:label>
              <span>Correo electrónico <span class="color-red">*</span></span>
            </template>
          </v-text-field>
        </div>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        :md="bAddOrEdit ? '4' : '6'"
        :lg="bAddOrEdit ? '4' : '6'"
        :xl="bAddOrEdit ? '4' : '6'"
      >
        <div class="content-txt-result-global">
          <v-select
            v-model="puestoUsuario"
            item-text="sName"
            item-value="sId"
            :items="itemsPuesto"
            class="custom-placeholder"
            label="Puesto*"
          >
            <template v-slot:label>
              <span>Puesto <span class="color-red">*</span></span>
            </template>
          </v-select>
        </div>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        :md="bAddOrEdit ? '4' : '6'"
        :lg="bAddOrEdit ? '4' : '6'"
        :xl="bAddOrEdit ? '4' : '6'"
      >
        <div class="content-txt-result-global">
          <v-text-field
            v-model="telefonoUsuario"
            class="text-Poppins"
            label="Teléfono"
            type="number"
            :hide-details="true"
          >
            <template v-slot:label>
              <span>Teléfono <span class="color-red">*</span></span>
            </template>
          </v-text-field>
        </div>
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="4" xl="4" v-if="bAddOrEdit">
        <div class="content-txt-result-global">
          <v-text-field
            v-model="contrasenaUsuario"
            class="text-Poppins"
            label=""
            autocomplete="new-password"
            :hide-details="true"
            :append-icon="bShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="() => (bShowPassword = !bShowPassword)"
            :type="bShowPassword ? 'text' : 'password'"
          >
            <template v-slot:label>
              <span>Contraseña <span class="color-red">*</span></span>
            </template>
          </v-text-field>
        </div>
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="4" xl="4" v-if="bAddOrEdit">
        <div class="content-txt-result-global">
          <v-text-field
            v-model="confirmarContrasenaUsuario"
            class="text-Poppins"
            label="Confirmar contraseña*"
            :hide-details="true"
            :append-icon="bShowConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="() => (bShowConfirmPassword = !bShowConfirmPassword)"
            :type="bShowConfirmPassword ? 'text' : 'password'"
          >
            <template v-slot:label>
              <span>Confrimar contraseña <span class="color-red">*</span></span>
            </template>
          </v-text-field>
        </div>
      </v-col>

      <v-col cols="12" class="pl-0 pr-0 mb-5">
        <div class="content-switch-permission-admin">
          <span class="txt-permission-admin">
            Permiso de edición administrativa
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="mdi mdi-information icon-information-permission-admin"
                />
              </template>
              <span
                >El permiso de edición administrativa le permite al usuario que
                lo tenga activo poder modificar <br />
                los campos informativos de una orden de compra específica sin
                importar su estado.</span
              >
            </v-tooltip>
          </span>
          <v-spacer></v-spacer>
          <v-switch
            class="mb-5"
            v-model="bAdminPermission"
            color="primary"
            :hide-details="true"
          ></v-switch>
        </div>
      </v-col>

      <v-col
        v-for="(item, index) in rolesUsuario"
        :key="index"
        cols="12"
        md="3"
      >
        <v-checkbox
          class="mt-0"
          v-model="aPermissions"
          :label="diccionarioCheckboxs[item.sName]"
          :value="item.sRoleId"
          hide-details
        ></v-checkbox>
      </v-col>

      <div class="divider-global mt-5"></div>
    </v-row>

    <v-row class="mt-5">
      <v-col cols="12">
        <div class="content-btns-add-or-edit-users">
          <v-btn
            v-if="!bAddOrEdit"
            color="#E05141"
            width="150"
            outlined
            class="btn-secundary-global mr-2"
            @click="deleteUsers"
          >
            Eliminar
          </v-btn>
          <v-btn
            color="black"
            width="150"
            outlined
            class="btn-secundary-global mr-2"
            @click="goBack"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="!bAddOrEdit"
            width="180"
            color="primary"
            class="btn-primary-global mr-2"
            @click="changePassword"
          >
            Cambiar contraseña
          </v-btn>
          <v-btn
            width="180"
            color="primary"
            class="btn-primary-global"
            @click="setAction"
            :loading="loadingBtnAddOrEdit"
            :disabled="bAddOrEdit ? !bBtnAdd : bBtnEdit"
          >
            {{ bAddOrEdit ? "Agregar" : "Guardar cambios" }}
          </v-btn>
        </div>
      </v-col>
      <!-- <v-col cols="12" sm="6" md="6" >
        <v-btn
          @click="dialog = false"
          color="black"
          outlined
          class="btn-secundary-global"
          width="175"
          >Cancelar
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6" md="6" >
        <v-btn
          width="175"
          color="primary"
          class="btn-primary-global"
          :disabled="!validateForm"
          @click="setCreateUser"
          :loading="loadingBtnAddOrEdit"
          >Crear
        </v-btn>
      </v-col> -->
    </v-row>

    <ChangepasswordDialog
      @close="openDialogChangePass = false"
      :openDialogChangePass="openDialogChangePass"
      :itemDetails="oItem"
    ></ChangepasswordDialog>
  </div>
</template>

<script>
import ChangepasswordDialog from "@/components/views/Users/ChangePassword";

export default {
  components: {
    ChangepasswordDialog,
  },
  data() {
    return {
      itemsPuesto: [
        {
          sName: "Administrativo",
          sId: "1",
        },
        {
          sName: "Residente",
          sId: "2",
        },
        {
          sName: "Director",
          sId: "3",
        },
      ],
      rolesUsuario: [],
      aPermissions: [],
      itemsCheckbox: [],

      diccionarioCheckboxs: {
        Administrator: "Administrador",
        PurchaseOrder: "Alta de órdenes de compra",
        ManagementPurchaseOrders: "Gestion de órdenes de compra",
        Supplier: "Alta de Proveedores",
        //Projects: "Creación de proyectos inmobiliarios",
        Projects: "Creación de proyectos",
        RequestForMaterials: "Solicitud de material",
        PurchaseUser: "Alta de usuarios",
        ExpenseManagement: "Gestión de gastos",
      },
      bAdminPermission: false,
      bShowPassword: false,
      bShowConfirmPassword: false,
      dialog: false,
      nombreUsuario: "",
      correoUsuario: "",
      puestoUsuario: "",
      telefonoUsuario: "",
      contrasenaUsuario: "",
      confirmarContrasenaUsuario: "",
      proyectoUsuario: "",
      solicitudMaterialUsuario: "",
      ordenCompraUsuario: "",
      gestionOrdenCompraUsuario: "",
      proveedorUsuario: "",
      usuarioUsuario: "",
      sSearch: "",
      loadingBtnAddOrEdit: false,
      oItem: {},
      openDialogChangePass: false,
    };
  },
  mounted() {
    this.getPermissions();
  },
  methods: {
    getUserById() {
      if (!this.bAddOrEdit) {
        db.get(`${uri}/api/v1/users/${this.$route.params.sIdUser}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        })
          .then((resp) => {
            this.oItem = resp.data.user;
            this.getRoles();
            this.nombreUsuario = this.oItem.sFullName;
            this.correoUsuario = this.oItem.sEmail;
            this.puestoUsuario = this.oItem.iPositionId.toString();
            this.bAdminPermission = this.oItem.bAdminPermission;
            this.telefonoUsuario = this.oItem.sPhoneNumber;
            this.itemsCheckbox = this.oItem.aPermissions;
            this.setCheckbox();
            this.$store.commit("refresher", false);
          })
          .catch((err) => {
            this.bLoading = false;
            const alert = {
              color: "red",
              text: err.response.data.message,
            };
            this.$store.commit("toggle_alert", alert);
          });
      }
    },
    getRoles() {
      this.bLoading = true;
      // get items
      db.get(`${uri}/api/v1/roles/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.rolesUsuario = resp.data.roles.filter((e) => {
            if (!e.sName.includes("Administrator")) {
              return e;
            }
          });
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    setCheckbox() {
      var items = [];
      for (let i = 0; i < this.itemsCheckbox.length; i++) {
        const element = this.itemsCheckbox[i];
        items.push(element.sRoleId);
      }
      this.aPermissions = items;
    },
    goBack() {
      this.$emit("goBack");
    },
    setAction() {
      if (this.bAddOrEdit) {
        this.setCreateUser();
      } else {
        this.setEditUser();
      }
    },
    setCreateUser() {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (regex.test(String(this.correoUsuario).toLowerCase())) {
        if (
          this.contrasenaUsuario == this.confirmarContrasenaUsuario ||
          this.confirmarContrasenaUsuario == this.contrasenaUsuario
        ) {
          this.setAddUser();
        } else {
          const alert = {
            color: "red",
            text: "Las contraseñas deben de ser iguales.",
          };
          this.$store.commit("toggle_alert", alert);
        }
      } else {
        const alert = {
          color: "red",
          text: "El formato del correo electrónico es incorrecto.",
        };
        this.$store.commit("toggle_alert", alert);
      }
    },
    setAddUser() {
      this.loadingBtnAddOrEdit = true;

      const payload = {
        sFullName: this.nombreUsuario,
        sEmail: this.correoUsuario,
        sPassword: this.confirmarContrasenaUsuario,
        sPosition: this.puestoUsuario,
        sPhoneNumber: this.telefonoUsuario,
        aPermissions: this.aPermissions,
        bAdminPermission: this.bAdminPermission,
      };

      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          contentType: "application/x-www-form-urlencoded;charset=utf-8",
        },
      };
      db.post(`${uri}/api/v1/users`, payload, config)
        .then((response) => {
          const alert = {
            color: "green",
            text: response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
          this.$store.commit("refresher", true);
          this.$router.push({ name: "Users" });
        })
        .catch((err) => {
          this.bLoading = false;
          this.loadingBtnAddOrEdit = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    setEditUser() {
      this.loadingBtnAddOrEdit = true;

      const params = {
        sFullName: this.nombreUsuario,
        sEmail: this.correoUsuario,
        sPhoneNumber: this.telefonoUsuario,
        sPosition: this.puestoUsuario,
        aPermissions: this.aPermissions,
        bAdminPermission: this.bAdminPermission,
      };

      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
        },
      };

      db.put(`${uri}/api/v1/users/${this.oItem.sUserId}`, params, config)
        .then((resp) => {
          this.loadingBtnAddOrEdit = false;
          const alert = {
            color: "green",
            text: resp.data.message,
          };
          this.$store.commit("toggle_alert", alert);
          this.$store.commit("refresher", true);
        })
        .catch((err) => {
          this.bLoading = false;
          this.loadingBtnAddOrEdit = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getPermissions() {
      db.get(`${uri}/api/v1/roles/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.rolesUsuario = resp.data.roles.filter((e) => {
            if (!e.sName.includes("Administrator")) {
              return e;
            }
          });
          this.getUserById();
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },

    changePassword() {
      this.openDialogChangePass = !this.openDialogChangePass;
    },
    deleteUsers() {
      var item = this.oItem;
      this.$store.commit("setDeleteItems", {
        id: item.sUserId,
        route: "/api/v1/users/" + item.sUserId,
        unit: " usuarios.",
        bReturn: true,
        sToReturn: "Users",
        msgDelete: "Estás a punto de eliminar un elemento",
        msgQuestionDelete: "¿Estás seguro que deseas realizar esta acción?",
      });
    },
    getValidatePermissions() {
      let bPermission = true;
      if (this.aPermissions.length > 0) {
        for (let i = 0; i < this.aPermissions.length; i++) {
          const aPermissionsEdit = this.aPermissions[i];
          const aPermissionOrigin = null;
          if (this.oItem.aPermissions[i]) {
            aPermissionOrigin = this.oItem.aPermissions[i].sRoleId;
          }
          if (aPermissionsEdit == aPermissionOrigin) {
            bPermission = true;
          } else {
            bPermission = false;
            return false;
          }
        }
      }
      return bPermission;
    },
  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
    bBtnAdd() {
      return (
        this.nombreUsuario !== "" &&
        this.correoUsuario !== "" &&
        this.puestoUsuario !== "" &&
        this.telefonoUsuario !== "" &&
        this.contrasenaUsuario !== "" &&
        this.confirmarContrasenaUsuario !== "" &&
        this.aPermissions.length > 0
      );
    },
    bBtnEdit() {
      return (
        (this.nombreUsuario === "" ||
          this.nombreUsuario === this.oItem.sFullName) &&
        (this.correoUsuario === "" ||
          this.correoUsuario == this.oItem.sEmail) &&
        (this.puestoUsuario === "" ||
          this.puestoUsuario == this.oItem.iPositionId) &&
        (this.telefonoUsuario === "" ||
          this.telefonoUsuario === this.oItem.sPhoneNumber) &&
        this.bAdminPermission === this.oItem.bAdminPermission &&
        this.getValidatePermissions()
      );
    },
    bAddOrEdit() {
      return this.$route.params.sIdUser === "0";
    },
  },
  watch: {
    refreshTable() {
      this.getUserById();
    },
  },
};
</script>
<style>
.txt-title-global {
  font-family: "Poppins", sans-serif;
  color: #000000;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0px !important;
}
.txt-result-global {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #707070;
  margin-bottom: 0px !important;
}
.content-add-or-edit-user-global {
  padding: 10px 15px;
  border-top: 1px solid #e0e0e0;
}
.content-btns-add-or-edit-users {
  display: flex;
}

.btn-secundary-global {
  font-family: "Poppins", sans-serif !important;
  height: 45px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  border-radius: 5px !important;
}
.btn-secundary-delete-global {
  font-family: "Poppins", sans-serif !important;
  height: 45px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  border-radius: 5px !important;
}

.divider-global {
  height: 1px !important;
  background-color: #e0e0e0 !important;
  width: 100%;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.content-switch-permission-admin {
  background-color: #f8f8f8 !important;
  border-radius: 24px !important;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
}
.txt-permission-admin {
  color: #000000;
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  font-weight: bolder;
}

.icon-information-permission-admin {
  color: #1e88e5 !important;
  font-size: 18px !important;
}
</style>
